body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.supplier-tier {
  display: flex;
  width: 82px;
}

.custom-width-article .RaFormInput-input-62,
.custom-width-article .RaFormInput-input-203,
.custom-width-article .RaFormInput-input-245 {
  width: max-content;
}

.article-compositon {
  width: 300px;
  display: flex;
}

.ecommerce-reference-project-id-hidde {
  visibility: hidden;
  position: absolute !important;
}

.project-upload-files {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: block;
}

.hide-loading {
  display: none;
}

.project-upload-files::before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: .4;
}

#originWarehouseKm {
  width: 100%;
}

.account-feature-checkbox {
  width: max-content !important;
}

.account-reset-references {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-reset-references > label {
  padding: 3px 16px !important;
  text-transform: none !important;
  color: #fff;
  background-color: black;
  border-radius: 10px;
}

.account-reset-references > label:hover {
  background-color: #000;
  opacity: 0.8;
}

.account-reset-references > span:first-child {
  width: 100px !important;
}

.reference-used {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  display: block;
}

.reference-used > span {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.retail-packaging-process > img {
  width: 40px;
  height: 40px;
}

.formatted-number-field {
  margin: 8px 0 4px 0;
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.formatted-number-field > span.label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  position: relative;
}

.formatted-number-field > span.value {
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  padding: 8px 0 4px;
  background: none;
  box-sizing: content-box;
  vertical-align: middle;

  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}